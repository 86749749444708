<template>
  <div class="search-io">
    <a-form
      ref="formRef"
      :model="formState"
      label-align="left"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
      layout="vertical"
    >
      <a-form-item :label="$t('identity_object.form_label.search_by_info')">
        <cds-input
          class="w-100 ant-input-sm"
          maxlength="100"
          size="small"
          :disabled="disabled"
          :read-only="readOnly"
          v-model:value="formState.name"
          :placeholder="
            $t('identity_object.form_label.search_by_info_placeholder')
          "
        />
      </a-form-item>
      <a-form-item :label="$t('identity_object.form_label.search_by_type')">
        <cds-select
          ref="type"
          :options="OBJECT_TYPE_TEXT"
          v-model:value="formState.type"
          :allowClear="true"
          :placeholder="
            $t('identity_object.form_label.search_by_type_placeholder')
          "
        />
      </a-form-item>
      <a-form-item
        class="select-multi-group"
        :label="$t('identity_object.form_label.search_by_group')"
      >
        <SelectMulti
          ref="type"
          :options="listGroup"
          :fieldNames="{ label: 'name', value: 'id' }"
          v-model:value="formState.groupId"
          :placeholder="
            $t('identity_object.form_label.search_by_group_placeholder')
          "
          mode="multiple"
          :max-tag-count="3"
          :max-tag-text-length="15"
          :filter-name="'name'"
        />
      </a-form-item>
      <a-form-item>
        <div class="h-183px w-full relative mt-3">
          <UploadSearch v-model:value="formState.imgUrl"></UploadSearch>
        </div>
      </a-form-item>

      <div class="flex items-center justify-center">
        <clip-button
          type="primary"
          @click="emits('onSearchAdvanced', formState)"
          class="px-[25px]"
          >{{ $t('common.search') }}
        </clip-button>
      </div>
    </a-form>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, reactive } from 'vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import UploadSearch from '@/components/upload-search/index.vue';
import CdsInput from '@/components/input/Input.vue';
import { OBJECT_TYPE_TEXT } from '@/util/common-constant';
import { useStore } from 'vuex';
import SelectMulti from '@/components/select-multi/index.vue';

const emits = defineEmits(['onSearchAdvanced']);
const { state } = useStore();

const listGroup = computed(() => state.ioGroup.listGroup);

const props = defineProps({
  formState: {
    default: {},
  },
});

const rules = {};

const formState = reactive(props.formState);
</script>
