<template>
  <div v-if="value" class="absolute top-5px right-5px z-20 cursor-pointer">
    <cds-feather-icons
      color="#B5122E"
      type="x-circle"
      size="20"
      @click="onClearImage"
    />
  </div>
  <label
    :htmlFor="`basic_${name}`"
    class="h-full flex items-center rounded-full relative cursor-pointer"
    :class="{ 'w-full justify-between': !value, 'justify-end': value }"
    @drop="dropHandler($event)"
    @dragover="$event.preventDefault()"
    @dragenter="$event.preventDefault()"
  >
    <div class="relative bg-ems-gray700 w-full h-full">
      <div
        v-if="!value"
        class="w-full h-full flex flex-col justify-center gap-y-30px"
      >
        <div class="mx-auto flex items-center">
          <img
            src="../../static/img/icon/UploadIcon.svg"
            alt="Icon Upload"
            class="block mx-auto"
          />
        </div>
        <div class="text-ems-gray200 mx-auto text-center">
          {{ $t('drop_image_or_file') }}
          <span class="text-ems-main1">{{ $t('select_file') }}</span>
        </div>
      </div>
      <img
        v-if="value"
        :src="value"
        alt=""
        class="w-full h-full flex items-center object-cover"
      />
      <input
        :id="`basic_${name}`"
        type="file"
        class="absolute invisible"
        accept="image/png, image/jpg, image/jpeg"
        @change="handleUploadImage"
      />
    </div>
  </label>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
// import ConstantAPI from "@/config/ConstantAPI";
// import {DataService} from "@/dataService/dataService";
import VueTypes from 'vue-types';
import { DataService } from '../../dataService/dataService';
import ConstantAPI from '../../config/ConstantAPI';
import { checkResponse } from '../../util/common-utils';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  isVideo: false,
  value: {
    type: String,
    required: true,
  },
  accept: VueTypes.string,
  name: {
    type: String,
    default: 'image',
  },
});

const emit = defineEmits([]);
const isLoading = ref(false);

const handleUploadImage = async (e) => {
  e.preventDefault();
  const file = e.target.files[0];
  await updateFileValue(file);
};
const dropHandler = (e) => {
  updateFileValue(e.dataTransfer.files[0]);
  e.preventDefault();
};
const onClearImage = () => {
  emit('update:value', null);
};
const updateFileValue = async (file) => {
  const fileSizeMB = file.size / (1024 * 1024);

  if (fileSizeMB > 2) {
    notification.error({
      message: t('common.notification'),
      description: t('common.Image exceeds'),
      duration: 4,
    });
    return;
  }

  const fmData = new FormData();
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  fmData.append('file', file);
  emit('update:value', null);
  isLoading.value = true;
  const response = await DataService.upload(
    ConstantAPI.upload_service.UPLOAD,
    fmData,
    config
  );
  checkResponse(
    response,
    () => {
      emit('update:value', response.data.url);
      emit('change', file);
      isLoading.value = false;
    },
    () => {
      isLoading.value = false;
      notification.error({
        message: t('common.notification'),
        description: response.error,
        duration: 4,
      });
      emit('update:value', null);
    }
  );
};
</script>

<style scoped>

</style>
